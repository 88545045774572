import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import styles from './index.module.scss';
import pageStyles from '../styles/page.module.scss';

const Grin4UInfoSection = () => (
  <div className={styles.section}>
    <div className={[pageStyles.section, pageStyles.sectionHero].join(' ')}>
      <div className={pageStyles.inner}>
        <div className={pageStyles.sectionTitleMark}>
          Arrienda un Scooter Grin y muévete a donde quieras, las veces que quieras.
        </div>
      </div>
    </div>
    <div className={[pageStyles.section, pageStyles.sectionWhite].join(' ')}>
      <div className={pageStyles.inner}>
        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <div className={styles.icon}>
              <img src="/icons/ahorra.png" alt="" />
            </div>

            <div className={styles.info}>
              <div className={styles.title}>
                Ahorra
              </div>
              <div className={styles.description}>
                Evitas gastar en bencina, en pasajes de bus o taxis.
              </div>
            </div>
          </div>
          <div className={styles.benefit}>
            <div className={styles.icon}>
              <img src="/icons/cuida-al-planeta.png" alt="" />
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                Cuidas al planeta
              </div>
              <div className={styles.description}>
                Te moverás rápido en un transporte sostenible.
              </div>
            </div>
          </div>
          <div className={styles.benefit}>
            <div className={styles.icon}>
              <img src="/icons/cuidas-tu-salud.png" alt="" />
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                Cuidas tu salud
              </div>
              <div className={styles.description}>
                Evitas aglomeraciones
              </div>
            </div>
          </div>
          <div className={styles.benefit}>
            <div className={styles.icon}>
              <img src="/icons/mantenimientos-ilimitados.png" alt="" />
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                Mantenimientos ilimitados
              </div>
              <div className={styles.description}>
                Tu scooter siempre listo cuando lo necesites.
                {' '}
                Si tienes algún problema ¡lo cambiamos!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={[pageStyles.section, pageStyles.sectionGray].join(' ')}>
      <div className={pageStyles.inner}>
        <div className={pageStyles.sectionTitle}>¿Cómo funciona?</div>
        <div className={pageStyles.sectionContent}>
          <div className={styles.howItWorks}>
            <div className={styles.step}>
              <div className={styles.number}>1</div>
              <div className={styles.description}>
                Contrata tu plan y entregamos el scooter en tu domicilio
                {' '}
                o lugar de trabajo en 24 horas
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>2</div>
              <div className={styles.description}>
                Si tienes algún problema con el scooter lo cambiamos al día siguiente
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>3</div>
              <div className={styles.description}>
                Al finalizar el arriendo puedes renovarlo las veces que quieras
                {' '}
                o lo retiramos en tu domicilio o lugar de trabajo
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div className={[pageStyles.section, pageStyles.sectionWhite].join(' ')}>
      <div className={pageStyles.inner}>

        <div className={pageStyles.sectionSubtitle}>
          Elige tu plan y empieza a moverte por la ciudad de la forma más simple y
          {' '}
          por menos de $1.000 al día.
        </div>

        <div className={styles.plans}>
          <div className={styles.plan}>
            <div className={styles.planTitle}>
              Mensual
            </div>
            <div className={styles.planPrice}>
              $ 34.990
            </div>
            <div className={styles.planDescription}>
              Llega a tu trabajo, universidad, a visitar amigos. ¡A dónde tu quieras!
            </div>
            <a href={`https://api.whatsapp.com/send?phone=+56951218631&text=${encodeURIComponent('Hola, quiero arrendar un scooter por 1 mes')}`}>
              <div className={styles.planButton}>
                Quiero arrendar
              </div>
            </a>
          </div>
          <div className={[styles.plan, styles.plus].join(' ')}>
            <div className={styles.planTitle}>
              Bimestral
            </div>
            <div className={styles.planPrice}>
              $ 59.990
            </div>
            <div className={styles.planDescription}>
              Llega a tu trabajo, universidad, a visitar amigos. ¡A dónde tu quieras!
            </div>
            <a href={`https://api.whatsapp.com/send?phone=+56951218631&text=${encodeURIComponent('Hola, quiero arrendar un scooter por 2 meses')}`}>
              <div className={styles.planButton}>
                Quiero arrendar
              </div>
            </a>
          </div>
          <div className={styles.plan}>
            <div className={styles.planTitle}>
              Trimestral
            </div>
            <div className={styles.planPrice}>
              $ 79.990
            </div>
            <div className={styles.planDescription}>
              Llega a tu trabajo, universidad, a visitar amigos. ¡A dónde tu quieras!
            </div>
            <a href={`https://api.whatsapp.com/send?phone=+56951218631&text=${encodeURIComponent('Hola, quiero arrendar un scooter por 3 meses')}`}>
              <div className={styles.planButton}>
                Quiero arrendar
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className={[pageStyles.section, pageStyles.sectionGray].join(' ')}>
      <div className={pageStyles.inner}>
        <div className={pageStyles.sectionTitle}>¿Tienes dudas o consultas?</div>
        <div className={pageStyles.sectionSubtitle}>
          Contáctanos y te ayudaremos a elegir el mejor plan o resolver cualquier inconveniente.
        </div>
        <div className={pageStyles.sectionContent}>
          <div className={styles.contact}>
            <a href={`https://api.whatsapp.com/send?phone=+56951218631&text=${encodeURIComponent('Hola, tengo una consulta sobre arriendo de scooters')}`}>
              <div className={styles.contactButton}>
                <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: 32 }} />
                <span>Contáctanos</span>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>

  </div>

);

export default Grin4UInfoSection;
