/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { Connector } from 'mqtt-react-hooks';

import BlankLayout from './layouts/BlankLayout';
import PageLayout from './layouts/PageLayout';

import HomeModule from './modules/HomeModule';
import LoginModule from './modules/LoginModule';
import LogoutModule from './modules/LogoutModule';

const Routes = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.slice(1)); // id=123
    let currentParams = {};
    try {
      currentParams = JSON.parse(localStorage.getItem('__parameters'));
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    const parameters = {
      ...currentParams,
    };
    // eslint-disable-next-line guard-for-in
    for (const [k, v] of params) {
      parameters[k] = v;
    }
    localStorage.setItem('__parameters', JSON.stringify(parameters));
  }, []);
  return (
    <Connector
      brokerUrl="mqtt://broker.grow.mobi:9001"
      options={{ protocol: 'wss', username: 'growdevice', password: '4F7C84871LQD' }}
    >
      <BrowserRouter>
        <Switch>
          <BlankLayout exact path="/login" component={LoginModule} />
          <BlankLayout exact path="/logout" component={LogoutModule} />
          <PageLayout exact path="/" component={HomeModule} />
        </Switch>
      </BrowserRouter>
    </Connector>
  );
};

Routes.defaultProps = {
};

Routes.propTypes = {
};

export default Routes;
