/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import styles from './index.module.scss';

const PageLayout = ({
  component: Component,
  user,
  ...props
}) => (
  <Route
    {...props}
    render={(matchProps) => (
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.inner}>
              <img src="/grin-logo.svg" alt="Grin" className={styles.logo} />
            </div>
          </div>
          <div className={styles.body}>
            <Component {...matchProps} user={user} />
          </div>
          <div className={styles.footer}>
            <div className={styles.inner}>
              <div className={styles.info}>
                © Grin Chile
              </div>
              <div className={styles.contact}>
                <a href={`https://api.whatsapp.com/send?phone=+56951218631&text=${encodeURIComponent('Hola, tengo una consulta sobre arriendo de scooters')}`}>
                  <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: 24 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

PageLayout.defaultProps = {
  user: {},
};

PageLayout.propTypes = {
  component: PropTypes.any.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state && state.auth && state.auth.get('user') && state.auth.get('user').toJS(),
});

/*
const mapDispatchToProps = (dispatch) => ({

});
*/

export default connect(
  mapStateToProps,
  null, // mapDispatchToProps
)(PageLayout);
